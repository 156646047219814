import React, { useEffect, useRef, useState } from "react";
import Footer from "../Layout/Footer";
import "react-datepicker/dist/react-datepicker.css";
import Agenttab from "./UserTab";
import AgentMobileTab from "./UserMobileTab";
import APIConfig from "../Configuration/APIConfig";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useReactToPrint } from 'react-to-print';
import Header from "../Layout/Header";
export default function HotelBookingDetails() {
    const params = useParams();
    const navigate = useNavigate();
    const [hotelBooking, setHotelBooking] = useState('');
    const [hotel, setHotel] = useState({});
    const [rooms, setRooms] = useState([]);
    const [guests, setGuests] = useState([]);
    const contentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => contentRef.current,
    });
    const downloadPDF = () => {
        const input = contentRef.current;
        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF();
            const imgWidth = 210;
            const pageHeight = 297;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;
            let position = 0;

            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }

            pdf.save('hotelbookingvoucher.pdf');
        });
    };
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken")
        }
    }
    function getTimeFromMins(mins) {
        var h = mins / 60 | 0, m = mins % 60 | 0;
        var durationformat = h + "h " + m + "m";
        return durationformat;
    }
    const GetHotelBookingDetails = () => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETHOTELBYID + "?Id=" + params.hotelBookingId, { ...headerconfig })
            .then((response) => {
                setHotelBooking(response.data.response.data);
                setHotel(response.data.response.data.hotelDetails)
                setRooms(response.data.response.data.hotelRoomDetails.requestedHotels);
                setGuests(response.data.response.data.hotelGuestInfos);
            });
    };
    useEffect(() => {
        if (localStorage.getItem('userId') === null) {
            localStorage.clear();
            navigate("/");
        }
        else {
            GetHotelBookingDetails();
        }
    }, [])
    return (
        <div id="main-wrapper">
            <div>
                <Header></Header>
                <div className="clearfix"></div>                
                <div className="dashboard-menus border-top d-none d-lg-block" style={{backgroundColor:'cadetblue'}}>
                    <div className="container">
                        <Agenttab></Agenttab>
                    </div>
                </div>
                <div className="py-2 gray-simple position-relative">
                    <div className="container">
                        {/* Search Form */}
                        <div className="row justify-content-center align-items-center">
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0">
                                        <li className="breadcrumb-item"><a href="#" className="text-primary">Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Hotel Booking Details</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="gray-simple position-relative">
                    <div className="container">
                        <div className="row align-items-start justify-content-between gx-xl-4">
                            <div className="col-xl-12 col-lg-8 col-md-12">
                                {/* Personal Information */}
                                <div className="card">
                                <div ref={contentRef}>
                                    <div className="d-flex align-items-center justify-content-center flex-column text-center mb-5 mt-4">
                                        <h3 className="mb-0">Booking Confirmation!</h3>
                                    </div>
                                    <p style={{ paddingLeft: "20px" }}>Dear Traveler, Following are the hotel booking details</p>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row mb-3">
                                            <div className="col-lg-3">
                                                    <label><b>Booking No</b> : {hotelBooking.bookingNo}</label>
                                                </div>
                                                <div className="col-lg-3">
                                                    <label><b>Destination</b> : {hotelBooking.city},{hotelBooking.country}</label>
                                                </div>
                                                <div className="col-lg-3">
                                                    <label><b>Check-In </b> : {moment(hotelBooking.checkIn).format('Do MMM yyyy')}</label>
                                                </div>
                                                <div className="col-lg-3">
                                                    <label><b>Check-Out </b> : {moment(hotelBooking.checkOut).format('Do MMM yyyy')}</label>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                            <div className="col-lg-3">
                                                    <label><b>Hotel</b> : {hotel.hotelName}</label>
                                                </div>
                                                <div className="col-lg-3">
                                                    <label><b>Address</b> : {hotel.address},{hotel.cityName},{hotel.countryName}</label>
                                                </div>
                                                <div className="col-lg-3">
                                                    <label><b>Phone Number</b> : {hotelBooking.phoneNumber}</label>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                            <div className="col-lg-3">
                                                    <label><b>Booking Status</b> : {hotelBooking.bookingStatus=="FAILED"?<span className="badge bg-danger">FAILED</span>:<span className="badge bg-success">CONFIRMED</span>}</label>
                                                </div>
                                                <div className="col-lg-3">
                                                    <label><b>Guests</b> : {hotelBooking.noOfGuests}</label>
                                                </div>
                                                <div className="col-lg-3">
                                                    <label><b>Rooms</b> : {hotelBooking.noOfRooms}</label>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-lg-3">
                                                    <label><b>Base Price</b> : {Math.round(hotelBooking.hotelRoomDetails?.price)} INR</label>
                                                </div>
                                                <div className="col-lg-3">
                                                    <label><b>Tax</b> : {Math.round(hotelBooking.taxAndFee)} INR</label>
                                                </div>
                                                <div className="col-lg-3">
                                                    <label><b>TCS</b> : {Math.round(hotelBooking.tcsAmount)} INR</label>
                                                </div>
                                                <div className="col-lg-3">
                                                    <label><b>Total Price</b> : {Math.round(hotelBooking.totalPrice)} INR</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header">
                                            <h3>Rooms</h3>
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive table-card">
                                                <table className="table align-middle" id="customerTable">
                                                    <thead className="table-light">
                                                        <tr>
                                                        <th></th>
                                                            <th>Room Type</th>  
                                                            <th>Guests</th>                                                          
                                                            <th>Meal Type</th>                                                            
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list form-check-all">
                                                        {rooms.length > 0 && rooms.map((v, index) =>
                                                            <tr key={index}>
                                                                <td>{index +1}</td>
                                                                <td className="id">{v.roomType}</td>
                                                                <td className="id">Adults : {v.adults}, Kids: {v.kids}</td>
                                                                <td className="id">{v.mealType}</td>                                                                
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header">
                                            <h3>Guests</h3>
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive table-card">
                                                <table className="table align-middle" id="customerTable">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th className="text-center">Guest Name</th>  
                                                            <th className="text-center">Type</th>       
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list form-check-all">
                                                        {guests.length > 0 && guests.map((raa, index) =>
                                                            <React.Fragment key={index}>
                                                            {raa.adultsFields.length > 0 && raa.adultsFields.map((ra, adultIndex) => (
                                                                <tr key={adultIndex}>
                                                                    <td className="col-sm-3 text-center">{ra.title}. {ra.firstName} {ra.lastName}</td>
                                                                    <td className="col-sm-3 text-center">{ra.travelerType}</td>
                                                                </tr>
                                                            ))}
                                                            {raa.kidsFields.length > 0 && raa.kidsFields.map((ra, adultIndex) => (
                                                                <tr key={adultIndex}>
                                                                    <td className="col-sm-3 text-center">{ra.title}. {ra.firstName} {ra.lastName}</td>
                                                                    <td className="col-sm-3 text-center">{ra.travelerType}</td>
                                                                </tr>
                                                            ))}
                                                        </React.Fragment>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="text-center d-flex align-items-center justify-content-center">
                                        <button onClick={downloadPDF} className="btn btn-md btn-light-primary fw-semibold mx-2">Download Voucher</button>
                                        <button onClick={handlePrint} className="btn btn-md btn-light-primary fw-semibold mx-2">Print Voucher</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer></Footer>
                {/* <div style={{ visibility: "hidden" }}>
                    <DownloadFlightVoucher ref={contentRef} flightBookingId={params.flightBookingId}></DownloadFlightVoucher>
                </div> */}
            </div>
        </div>
    )
}